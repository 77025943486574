var EventEmitter = /** @class */ (function () {
    function EventEmitter() {
        this.eventHandlers = {};
    }
    EventEmitter.prototype.on = function (event, handler) {
        if (handler == null)
            throw new TypeError('handler cannot be null or undefined');
        var handlers = (this.eventHandlers[event] || (this.eventHandlers[event] = []));
        handlers.push(handler);
        return function () {
            // Once `handlers` has been set, the reference should never change
            var idx = handlers.indexOf(handler);
            idx >= 0 && handlers.splice(idx, 1);
        };
    };
    EventEmitter.prototype.emit = function (event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (this.eventHandlers[event]) {
            var handlers = this.eventHandlers[event].slice();
            handlers.forEach(function (fn) { return fn.apply(void 0, args); });
        }
    };
    return EventEmitter;
}());
export { EventEmitter };
