import { __awaiter, __extends, __generator } from "tslib";
import { noop } from 'core/noop';
import { EventEmitter } from 'core/event-emitter';
import { default as bootstrap } from 'common/bootstrap';
;
var Widget = /** @class */ (function (_super) {
    __extends(Widget, _super);
    function Widget(options) {
        var _this = _super.call(this) || this;
        _this.env = null;
        _this.options = options;
        _this.component = null;
        return _this;
    }
    Widget.prototype.getComponent = function () {
        return this.component;
    };
    Widget.prototype.bootstrap = function (config) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, Vue, widget, props, componentOptions;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, bootstrap(config)];
                    case 1:
                        _a.env = _b.sent();
                        Vue = this.env.Vue;
                        this.emit('init:before', this);
                        widget = this;
                        return [4 /*yield*/, this.createProps(this.env)];
                    case 2:
                        props = _b.sent();
                        this.props = Vue.observable(props);
                        return [4 /*yield*/, this.createComponentOptions(this.env, {
                                el: this.options.element,
                                errorCaptured: function (err, vm, info) {
                                    widget.emit('error', err);
                                }
                            })];
                    case 3:
                        componentOptions = _b.sent();
                        this.component = new Vue(componentOptions);
                        this.emit('init:after', this);
                        return [2 /*return*/];
                }
            });
        });
    };
    Widget.prototype.on = function (event, handler) {
        if (event !== 'init:after' || this.component == null)
            return _super.prototype.on.call(this, event, handler);
        // Handle situtations where someone attempts to listen to 'init:after' after initialisation is already completed.
        handler(this);
        return noop;
    };
    return Widget;
}(EventEmitter));
export { Widget };
