import { __awaiter, __generator } from "tslib";
import { polyfill } from 'core/polyfill';
;
var singleton = null;
export default function bootstrap(config) {
    return __awaiter(this, void 0, void 0, function () {
        var vueModule, Vue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        import('vue'),
                        polyfill()
                    ])];
                case 1:
                    vueModule = (_a.sent())[0];
                    Vue = vueModule.default;
                    // Any one-time initialisation can go inside this guard.
                    if (singleton == null) {
                        singleton = {};
                    }
                    return [2 /*return*/, {
                            Config: config,
                            Vue: Vue
                        }];
            }
        });
    });
}
