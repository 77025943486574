import { __awaiter, __extends, __generator } from "tslib";
import { Widget } from 'common/widget';
var ContextualHelpWidget = /** @class */ (function (_super) {
    __extends(ContextualHelpWidget, _super);
    function ContextualHelpWidget(options) {
        var _this = _super.call(this, options) || this;
        _this.bootstrap({ name: 'help.widget' });
        return _this;
    }
    ContextualHelpWidget.prototype.createProps = function (env) {
        // Initialise the props object from the passed-in options
        return {
            title: this.options.title || null,
            name: this.options.name || null,
            heading: this.options.heading || null,
            theme: this.options.theme || 'default',
            actions: this.options.actions || null,
            faqProvider: this.options.faqProvider || null,
            emptyMessage: this.options.emptyMessage || null
        };
    };
    ContextualHelpWidget.prototype.createComponentOptions = function (env, initialOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var Vue, WidgetView, props;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Vue = env.Vue;
                        return [4 /*yield*/, import('./views/widget.vue')];
                    case 1:
                        WidgetView = (_a.sent()).default;
                        props = this.props;
                        initialOptions.render = function (createElement) {
                            return createElement(WidgetView, {
                                props: props,
                                on: {}
                            });
                        };
                        return [2 /*return*/, initialOptions];
                }
            });
        });
    };
    return ContextualHelpWidget;
}(Widget));
export { ContextualHelpWidget };
